import './App.css';
import CalendlyWidget from './components/Calendly/Calendly';
import Etapes from './components/Etapes/Etapes';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import CardGroup from './components/cardgroup/CardGroup';
import Espace from './components/espace/Espace';
import Header from './components/header/Header';
import Laptop from './components/ordinateur/Laptop';
import Tablette from './components/tablette/tablette';

function App() {
  
  return (
    <div>
      <Navbar />
      <Header />
      <Espace />
      <Laptop />
      <CardGroup />
      <Espace />
      <Tablette />
      <Espace />
      <Etapes />
      <CalendlyWidget />
      <Footer />
    </div>
  );
}

export default App;

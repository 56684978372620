import React, { useState } from "react";
import "./Footer.css";

const Footer = () => {
    const [formStatus, setFormStatus] = useState('');

    const handleSubmit = async (event) => {
        const formData = new FormData(event.target);
        const formProps = Object.fromEntries(formData);

        try {
            const response = await fetch('http://wecydebackend.alwaysdata.net/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formProps),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseBody = await response.json();
            // Utilisez le message de la réponse ou un message par défaut
            setFormStatus(responseBody.message || "Message envoyé avec succès !");
        } catch (error) {
            console.error('Failed to send email:', error);
            setFormStatus("Échec de l'envoi du message.");
        }
    };

    return (
        <div className="container" id="contact">
            <h3>Envie d'un nouveau projet ?</h3>
            <div className="formulaire">
                <form onSubmit={handleSubmit}>
                    <div className="nomEtprenom">
                        <input type="text" name="nom" placeholder="Votre nom" />
                        <input type="text" name="prenom" placeholder="Votre Prénom" />
                    </div>
                    <div className="email">
                        <input type="email" name="email" placeholder="Adresse mail" />
                    </div>
                    <div className="telephone">
                        <input type="tel" name="phone" placeholder="06... le reste du numéro de téléphone" pattern="[0-9]{10}" />
                    </div>
                    <div className="zone-message">
                        <textarea name="message" cols="30" rows="10" placeholder="Votre message"></textarea>
                    </div>
                    <button className="btn" type="submit">Envoyer</button>
                </form>
                {formStatus && <div className="form-status-message">{formStatus}</div>}
            </div>
        </div>
    );
}

export default Footer;
